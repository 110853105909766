<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Janta Darbar</div>
        <div class="col-lg-6 col-12 text-end d-flex justify-content-end custom-flex-cloumn-mob">
            <button type="button" class="header-btn-outer btn btn-primary" @click="addjanataeventModalOpen()">
                Add Janta Darbar
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="jantadarbarList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
            :paginator="true" :rows="30" :lazy="true" :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" dataKey="aw1" class="p-datatable-sm">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="datetime" header="Date & Time" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                    <div>
                        <div>
                            {{ format_datetimestamp(data.dq4) }}
                        </div>
                        <div>
                            {{ format_timestamp(data.dq4) }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="address" header="Address" headerStyle="width: 41%" bodyStyle="width: 41%">
                <template #body="{ data }">
                    <div>
                        <div>
                            {{ data.dq5 ? data.dq5 : "N/A" }}
                        </div>
                        <Divider v-if="data.dq6" />
                        <a v-if="data.dq6" style="word-break: break-all;" :href="data.dq6" target="_blank">{{ data.dq6 }}</a>
                    </div>
                </template>
            </Column>
            <Column field="image" header="Image" class="justify-content-center" headerStyle="width: 15%"
                bodyStyle="width: 15%">
                <template #body="{ data }">
                    <img v-if="data.dq8 != null && data.dq8 != ''"
                  :src="'https://storage.googleapis.com/' + this.storageimgpath + '/jantadarbar/' + data.dq7"
                  class="cursor-pointer" alt="" @click="eventimagepreview(data.dq7)" width="100" height="100" />
                </template>
            </Column>
            <Column field="status" header="Status" class="justify-content-center" headerStyle="width: 10%"
                bodyStyle="width: 10%">
                <template #body="{ data }">
                    <div>
                        <InputSwitch v-model="data.dq9" :true-value="dynamicTrueActiveValue"
                            :false-value="dynamicFalseInactiveValue" @change="confirmModalOpen(data)" />
                    </div>
                </template>
            </Column>
            <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 10%"
                bodyStyle="width: 10%">
                <template #body="{ data }">
                    <button type="button" title="Edit Details" class="btn custom-outline-view-btn"
                        @click="editjanataeventModalOpen(data)">
                        <i class="pi pi-pencil call-btn-color"></i> Edit
                    </button>
                </template>
            </Column>
            <Column field="count" header="Participants" class="justify-content-center" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                            <div class="me-2"> 
                                <span class="status-active">{{ data.participatedcount }}</span>
                            </div>
                            <button type="button" title="View Details" class="btn custom-outline-view-btn"
                        @click="darbarParticioantRedirectPage(data)">
                        <i class="pi pi-eye call-btn-color"></i>
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Add Custom Janta Darbar Start Here -->
    <div class="modal-mask" v-if="addCustomEventDialog">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Janta Darbar</h5>
                    <button type="button" class="btn-close" @click="addjanataeventModalClose()"></button>
                </div>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-12">
                            <div class="custom-form-group">
                                <label for="formBirthDateInput" class="form-label">Select Date & Time <span
                                        class="text-danger">*</span></label>
                                <div class="input-group">
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <Calendar v-model="add.scheduledate" :showIcon="true"
                                            class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                            dateFormat="dd/mm/yy" :minDate="todaysDate" :manualInput="false"
                                            :monthNavigator="true" :yearRange="'2020:' + new Date().getFullYear()"
                                            appendTo="body" :yearNavigator="true" />

                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <Calendar id="time12" placeholder="HH:MM" v-model="add.scheduletime"
                                            :timeOnly="true" :manualInput="false" hourFormat="12"
                                            class="custom-ultima-calendar custom-time-outer" appendTo="body"
                                            :disabled="!add.scheduledate" :stepMinute="15" />

                                    </div>
                                </div>
                                <div class="custom-error" v-if="v$.add.scheduledate.$error">
                                    {{ v$.add.scheduledate.$errors[0].$message }}
                                </div>
                                <div class="custom-error" v-if="add.scheduledate != null && v$.add.scheduletime.$error">
                                    {{ v$.add.scheduletime.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Address<span class="text-danger">*</span></label>
                                <textarea type="text" v-model="add.address" class="form-control custom-text-area-scroll"
                                    placeholder="Enter Address" autocomplete="off" rows="2" maxlength="250"></textarea>
                                <div class="custom-error" v-if="v$.add.address.$error">
                                    {{ v$.add.address.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Google Map Link</label>
                                <input type="text" :searchable="true" label="label" v-model="add.maplink"
                                    class="form-control" id="formLastNameInput"
                                    placeholder="Enter Google Map Link" autocomplete="off" maxlength="250" required />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Image</label>
                                        <FileUpload mode="basic" ref="darbareventrefadd" class="custom-upload-btn-ultima"
                                            v-model="add.eventimageimg" accept="image/*"
                                            v-on:change="handleUploadDarbarBannerAdd()" />
                                        <div class="custom-error" v-if="erroruploadimageadd">{{ erroruploadimageadd }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12"
                                    v-if="createpreviewurladd != null && createpreviewurladd != ''">
                                    <img :src="createpreviewurladd.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn user-role-btn" @click="addjanataeventbtn()"
                            :disabled="addbtnloader" style="width: 115px;">
                            <span v-if="!addbtnloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addbtnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Custom Janta Darbar End Here -->
     <!-- Edit Custom Janta Darbar Start Here -->
    <div class="modal-mask" v-if="editCustomEventDialog">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Edit Janta Darbar</h5>
                    <button type="button" class="btn-close" @click="editjanataeventModalClose()"></button>
                </div>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-12">
                            <div class="custom-form-group">
                                <label for="formBirthDateInput" class="form-label">Select Date & Time <span
                                        class="text-danger">*</span></label>
                                <div class="input-group">
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <Calendar v-model="edit.scheduledate" :showIcon="true"
                                            class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                            dateFormat="dd/mm/yy" :minDate="todaysDate" :manualInput="false"
                                            :monthNavigator="true" :yearRange="'2020:' + new Date().getFullYear()"
                                            appendTo="body" :yearNavigator="true" @date-select="scheduledatechanged()" />

                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6">
                                        <Calendar id="time12" placeholder="HH:MM" v-model="edit.scheduletime"
                                            :timeOnly="true" :manualInput="false" hourFormat="12"
                                            class="custom-ultima-calendar custom-time-outer" appendTo="body"
                                            :disabled="!edit.scheduledate" :stepMinute="15" @click="scheduletimechanged()" />

                                    </div>
                                </div>
                                <div class="custom-error" v-if="v$.edit.scheduledate.$error">
                                    {{ v$.edit.scheduledate.$errors[0].$message }}
                                </div>
                                <div class="custom-error" v-if="edit.scheduledate != null && v$.edit.scheduletime.$error">
                                    {{ v$.edit.scheduletime.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Address<span class="text-danger">*</span></label>
                                <textarea type="text" v-model="edit.address" class="form-control custom-text-area-scroll"
                                    placeholder="Enter Address" autocomplete="off" rows="2" maxlength="250"></textarea>
                                <div class="custom-error" v-if="v$.edit.address.$error">
                                    {{ v$.edit.address.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Google Map Link</label>
                                <input type="text" :searchable="true" label="label" v-model="edit.maplink"
                                    class="form-control" id="formLastNameInput"
                                    placeholder="Enter Google Map Link" autocomplete="off" maxlength="250" required />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Image</label>
                                        <FileUpload mode="basic" ref="darbareventrefedit" class="custom-upload-btn-ultima"
                                            v-model="edit.eventimageimg" accept="image/*"
                                            v-on:change="handleUploadDarbarBannerEdit()" />
                                        <div class="custom-error" v-if="erroruploadimageedit">{{ erroruploadimageedit }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12"
                                    v-if="createpreviewurledit != null && createpreviewurledit != ''">
                                    <img :src="createpreviewurledit.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                                </div>
                                <div class="col-lg-4 col-md-4 col-12"
                                    v-if="(createpreviewurledit == '' || createpreviewurledit == null) && (previewurleditimage != null && previewurleditimage != '')">
                                    <img :src="previewurleditimage" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img test
                                  " alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn user-role-btn" @click="editjanataeventbtn()"
                            :disabled="editbtnloader" style="width: 115px;">
                            <span v-if="!editbtnloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="editbtnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Edit Custom Janta Darbar End Here -->
    <!-- confirmation modal start here -->
    <div class="modal-mask" v-if="confirm_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="changeDarbarStatus()"
                            :disabled="showconfirmloaderbtn">
                            <span v-if="!showconfirmloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation modal end here -->
     <!-- Image Preview modal start here -->
    <div v-if="ImagePreviewDialog" class="modal-mask">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Image Preview</h4>
                    <button class="btn-close" @click="eventimagepreviewClose"></button>
                </div>
                <div class="modal-body p-0">
                    <img v-if="previewimg != null && previewimg != ''"
                  :src="'https://storage.googleapis.com/' + this.storageimgpath + '/jantadarbar/' + previewimg"
                  style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
    <!-- Image Preview modal end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from 'moment';
import router from "@/router";
export default {
    data() {
        return {
            v$: useValidate(),
            editbtnloader: false,
            addbtnloader: false,
            jantadarbarList: null,
            totalRecords: 0,
            loading: false,
            page_no: 0,
            addCustomEventDialog: false,
            roleid: "",
            todaysDate: new Date(moment().add(4, 'hours')._d),
            add: {
                scheduledate: null,
                scheduletime: null,
                address: '',
                maplink: '',
                eventimageimg: null,
            },
            edit: {
                scheduledate: null,
                scheduletime: null,
                address: '',
                maplink: '',
                eventimageimg: null,
            },
            erroruploadimageadd: '',
            erroruploadimageedit: '',
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,
            confirm_popup_status: false,
            showconfirmloaderbtn: false,
            confirm_designation_id: '',
            confirm_status_value: '',
            createpreviewurladd: '',
            createpreviewurledit: null,
            editCustomEventDialog: false,
            rowfkvalue: '',
            client_info: '',
            storageimgpath: '',
            ImagePreviewDialog: false,
            previewurleditimage: null,
            scheduletimechangedvalue: 0,
            scheduledatechangedvalue: 0,
        };
    },
    validations() {
        return {
            add: {
                scheduledate: {
                    required: helpers.withMessage("Please select date", required),
                },
                scheduletime: {
                    required: helpers.withMessage("Please select time", required),
                },
                address: {
                    required: helpers.withMessage("Please enter address", required),
                },
            },
            edit: {
                scheduledate: {
                    required: helpers.withMessage("Please select date", required),
                },
                scheduletime: {
                    required: helpers.withMessage("Please select time", required),
                },
                address: {
                    required: helpers.withMessage("Please enter address", required),
                },
            },
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.showmodalloader = true;
        this.getJantaDarbarList();
        this.client_info = JSON.parse(localStorage.client_info);
      this.storageimgpath = this.client_info.maa24;
    },
    methods: {
        getJantaDarbarList(e) {
            this.loading = true;
            this.ApiService.getJantaDarbarList(e).then((data) => {
                if (data.success == true) {
                    this.jantadarbarList = data.data;
                    this.totalRecords = data.totalCount;
                    this.loading = false;
                } else {
                    this.jantadarbarList = null;
                    this.totalRecords = 0;
                    this.loading = false;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getJantaDarbarList({ page_no: this.page_no });
        },
        addjanataeventModalOpen() {
            this.addCustomEventDialog = true;
            this.add.scheduledate = null;
            this.add.scheduletime = null;
            this.add.address = '';
            this.add.maplink = '';
            this.rowfkvalue = '';
            this.add.eventimageimg = null;
            this.addbtnloader = false;
            this.createpreviewurladd = '';
        },
        addjanataeventModalClose() {
            this.addCustomEventDialog = false;
            this.add.scheduledate = null;
            this.add.scheduletime = null;
            this.add.address = '';
            this.add.maplink = '';
            this.add.eventimageimg = null;
            this.createpreviewurladd = '';
            this.rowfkvalue = '';
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        addjanataeventbtn() {
            this.v$.add.$validate();
            var formData = new FormData();
            formData.append("dq1", this.rowfkvalue);
            formData.append('scheduledate', moment(this.add.scheduledate).format("YYYY-MM-DD"));
            formData.append('scheduletime', moment(this.add.scheduletime).format("HH:mm:ss"));
            formData.append("dq5", this.add.address);
            formData.append("dq6", this.add.maplink);
            if (this.add.eventimageimg) {
                formData.append("dq7", this.add.eventimageimg);
            }
            if (!this.v$.add.$error && !this.erroruploadimageadd) {
                this.addbtnloader = true;
                this.ApiService.addJantaDarbarEvent(formData).then((items) => {
                    if (items.success == true) {
                        this.$toast.open({
                            message: items.message,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.addbtnloader = false;
                        this.addCustomEventDialog = false;
                        this.getJantaDarbarList();
                    }
                    else {
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.addbtnloader = false;
                    }
                });
            }
        },

        editjanataeventModalOpen(e) {
            this.editCustomEventDialog = true;
            this.editbtnloader = false;
            this.rowfkvalue = '';
            this.edit.scheduledate = null;
            this.edit.scheduletime = null;
            this.edit.address = '';
            this.edit.maplink = '';
            this.edit.eventimageimg = null;
            this.createpreviewurledit = null;
            this.previewurleditimage = null;
            this.scheduletimechangedvalue = 0;
            this.scheduledatechangedvalue = 0;
            this.rowfkvalue = e.dq1;
            if (e.dq4 != null && e.dq4 != "") {
                var localscheduledate = new Date(e.dq4 * 1000).toUTCString();
                this.edit.scheduledate = moment.utc(localscheduledate).format("DD/MM/YYYY");
                var localscheduletime = new Date(e.dq4 * 1000).toUTCString();
                this.edit.scheduletime = moment.utc(localscheduletime).format("hh:mm A");
            }
            this.edit.address = e.dq5;
            this.edit.maplink = e.dq6;
            if(e.dq7 != null) {
                this.previewurleditimage = 'https://storage.googleapis.com/' + this.storageimgpath + '/jantadarbar/' + e.dq7;
            }
            
        },
        editjanataeventModalClose() {
            this.editCustomEventDialog = false;
            this.rowfkvalue = '';
            this.edit.scheduledate = null;
            this.edit.scheduletime = null;
            this.edit.address = '';
            this.edit.maplink = '';
            this.edit.eventimageimg = null;
            this.createpreviewurledit = null;
            this.previewurleditimage = null;
            this.rowfkvalue = '';
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        editjanataeventbtn() {
            this.v$.edit.$validate();
            var formData = new FormData();
            formData.append("dq1", this.rowfkvalue);
            if(this.scheduletimechangedvalue == 1) {
                formData.append('scheduletime', moment(this.edit.scheduletime).format("HH:mm:ss"));
            }
            if(this.scheduledatechangedvalue == 1) {
                formData.append('scheduledate', moment(this.edit.scheduledate).format("YYYY-MM-DD"));    
            }
            formData.append("dq5", this.edit.address);
            formData.append("dq6", this.edit.maplink);
            if (this.edit.eventimageimg) {
                formData.append("dq7", this.edit.eventimageimg);
            }
            if (!this.v$.edit.$error && !this.erroruploadimageedit) {
                this.editbtnloader = true;
                this.ApiService.addJantaDarbarEvent(formData).then((items) => {
                    if (items.success == true) {
                        this.$toast.open({
                            message: items.message,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.editbtnloader = false;
                        this.editCustomEventDialog = false;
                        this.getJantaDarbarList();
                    }
                    else {
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.editbtnloader = false;
                    }
                });
            }
        },
        handleUploadDarbarBannerAdd() {
            var file = this.$refs.darbareventrefadd.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.erroruploadimageadd = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.erroruploadimageadd = 'Invalid file type';
            } else {
                this.erroruploadimageadd = '';
                this.add.eventimageimg = this.$refs.darbareventrefadd.files[0];
                this.createpreviewurladd = this.add.eventimageimg;
            }
        },
        handleUploadDarbarBannerEdit() {
            var file = this.$refs.darbareventrefedit.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.erroruploadimageedit = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.erroruploadimageedit = 'Invalid file type';
            } else {
                this.erroruploadimageedit = '';
                this.edit.eventimageimg = this.$refs.darbareventrefedit.files[0];
                this.createpreviewurledit = this.edit.eventimageimg;
            }
        },
        confirmModalOpen(e) {
            this.confirm_designation_id = e.dq1;
            this.confirm_status_value = e.dq9;
            this.confirm_popup_status = true;
        },

        confirmModalClose() {
            this.confirm_popup_status = false;
            this.confirm_designation_id = '';
            this.confirm_status_value = '';
            this.getJantaDarbarList();
        },
        changeDarbarStatus() {
            let fields = {};
            fields["dq1"] = this.confirm_designation_id;
            if (this.confirm_status_value == 0 || this.confirm_status_value == false) {
                fields["dq9"] = 0;
            }
            if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
                fields["dq9"] = 1;
            }
            this.showconfirmloaderbtn = true;
            this.ApiService.updateJantaDabarStatus(fields).then((items) => {
                if (items.status === 200) {
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.showconfirmloaderbtn = false;
                    this.getJantaDarbarList();
                    this.confirm_designation_id = '';
                    this.confirm_status_value = '';
                    this.confirm_popup_status = false;
                } else {
                    this.showconfirmloaderbtn = false;
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
        format_datetimestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment.utc(localTime).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment.utc(localTime).format("hh:mm A");
            } else {
                return 'N/A';
            }
        },
        eventimagepreview(image) {
            this.previewimg = image;
            this.ImagePreviewDialog = true;
        },
        eventimagepreviewClose() {
            this.ImagePreviewDialog = false;
        },
        scheduledatechanged() {
            this.scheduledatechangedvalue = 1;
        },
        scheduletimechanged() {
            this.scheduletimechangedvalue = 1;
        },
        darbarParticioantRedirectPage(data) {
            let routeData = router.resolve({
            name: "VoterParticioantLogs",
            params: { eventId: data.dq1 },
            });
            window.open(routeData.href, "_blank");
        }
    },
};
</script>
<style scoped>
.status-active {
    background: #c8e6c9;
    border-radius: 4px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 8px 10px;
    text-transform: capitalize;
    border: 1px solid #4daa53;
  line-height: 12px;
}
.custom-outline-participatedcount-btn{
    background: #E4F8F0 0% 0% no-repeat padding-box;
    border: 1px solid #BBD9CC;
    border-radius: 4px;
    line-height: 10px;
    min-width: 32px;
    height: 32px;
  }
  .custom-outline-participatedcount-btn .participatedcount-count{
    color:#2CA270;
    font-size: 18px;
  }
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.btn.user-role-btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    min-width: 80px;
}

.user-role-btn:focus {
    box-shadow: none;
}

.modal-body-fixed-height {
    min-height: 260px;
}
</style>